export function useTextFunctions() {
  const createUserInitials = (username: string) => {
    if (typeof username !== 'string') return '';

    const words = username.trim().split(' ');
    if (words.length === 0) return '';

    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase();
    }

    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words[1].charAt(0).toUpperCase();

    return firstInitial + secondInitial;
  };
  const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  const truncateText = (text: string, limit: number) => {
    return text.slice(0, limit) + (text.length > limit ? '...' : '');
  };
  return {
    createUserInitials,
    capitalize,
    truncateText
  };
}
