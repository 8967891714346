<template>
  <textarea
    :value="internalValue"
    :placeholder="props.placeholder"
    class="w-full bg-primary_900 rounded-lg placeholder:text-neutral_200 text-base_white outline-none p-6 border border-transparent focus:border-primary_500 hover:border-primary_700 box-border"
    :class="error ? 'border-error_400' : ''"
    rows="6"
    @input="updateValue($event.target as HTMLInputElement)"
    @blur="handleBlur"
  />
  <div v-if="error">
    <p
      v-for="error of errorMessages"
      :key="error.$uid"
      class="mt-1 ml-2 text-error_400 text-[14px] font-medium"
    >
      {{ error.$message }}
    </p>
  </div>
</template>
<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    required: true
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessages: {
    type: Array as PropType<ErrorObject[]>,
    default: () => []
  }
});
const emit = defineEmits(['update:modelValue', 'blur']);
const internalValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);
const updateValue = (target: HTMLInputElement | null) => {
  if (target) {
    const newValue: any = target.value;
    internalValue.value = newValue;
    emit('update:modelValue', newValue);
  }
};

const handleBlur = () => {
  emit('blur');
};
</script>
<style scoped>
textarea {
  resize: none;
}
</style>
