<template>
  <div
    class="flex flex-col sm:rounded-[12px] pb-4 sm:px-6 sm:py-4 items-center border-b sm:border-b-0 border-neutral_500 relative"
    :class="
      props.place != 'onRepostedPost'
        ? 'sm:bg-neutral_700 '
        : 'bg-neutral_600 sm:bg-neutral_500 px-4 sm:px-6 py-3 sm:py-4 rounded-[12px]'
    "
  >
    <div class="w-full flex flex-col sm:flex-row items-start gap-2 sm:gap-4 relative">
      <!-- <UserPicture
        v-if="!commonStore.isMobile"
        class="shrink-0 -mt-[6px]"
        :display-username="false"
        :user="props.post.user"
        size="md"
      /> -->
      <div class="w-full flex flex-col gap-2">
        <PostHeader
          :post="props.post"
          :place="props.place"
          @open-edit-dialog="isEditDialogOpen = true"
          @post-deleted="emits('post-deleted')"
        />
        <div @mouseenter="isTitleHovered = true" @mouseleave="isTitleHovered = false">
          <div v-if="props.post.type == 'betting_shop_review'">
            <NuxtLink :to="`/betting-shop/${props.post.bettingShopReview?.bettingShopId}`">
              <div
                class="px-4 py-6 sm:p-6 flex items-center shadow-md justify-between bg-neutral_600 my-2 rounded-[8px]"
              >
                <div class="flex items-center gap-6">
                  <img
                    class="h-[40px] w-[40px] sm:h-[72px] sm:w-[72px]"
                    :src="props.post.bettingShop?.logo"
                    :alt="props.post.bettingShop?.name"
                  />
                  <p
                    class="text-[16px] font-bold leading-[150%] tracking-[-0.32px] text-base_white"
                  >
                    {{ props.post.bettingShop?.name }}
                  </p>
                </div>
                <div class="flex items-center gap-6">
                  <BoneRating
                    v-if="props.post.bettingShopReview?.avgScore"
                    :size="commonStore.isMobile ? 'md' : 'lg'"
                    :rating="props.post.bettingShopReview.avgScore"
                  />
                  <p
                    class="text-[12px] text-base_white sm:text-[22px] font-medium sm:font-bold leading-[120%]"
                  >
                    {{ props.post.bettingShopReview?.avgScore }}
                  </p>
                </div>
              </div>
            </NuxtLink>
          </div>
          <NuxtLink :to="localePath(`/post/${props.post.id}`)">
            <p
              ref="description"
              class="mt-2 mb-2 sm:mb-3 text-base text-left text-neutral_50 whitespace-pre-wrap font-medium leading-[150%] duration-300"
              :class="[
                isTitleHovered ? 'text-neutral_300' : '',
                props.showFullText ? '' : 'truncate-ellipsis'
              ]"
            >
              {{ props.post.postDescription }}
            </p>
          </NuxtLink>
          <NuxtLink v-if="isTextTruncated" :to="localePath(`/post/${props.post.id}`)">
            <p class="text-base font-medium leading-[150%] mb-2 -mt-1 text-primary_500">Ceo post</p>
          </NuxtLink>
          <div
            v-if="props.post.postTitle"
            class="h-px mb-2 w-full bg-primary_900 sm:bg-primary_800"
          />
          <a v-if="props.post.externalUrl" target="_blank" :href="props.post.externalUrl">
            <div class="flex gap-3">
              <ClientOnly>
                <ion-icon class="text-[24px] shrink-0 text-primary_500" name="link-outline" />
              </ClientOnly>
              <p
                v-if="props.post.postTitle"
                class="text-[18px] sm:text-xl font-bold text-neutral_50 leading-[120%] text-left tracking-[-0.4px] duration-300"
                :class="isTitleHovered ? 'text-primary_300' : ''"
              >
                {{ props.post.postTitle }}
              </p>
            </div>
          </a>
        </div>
        <div v-if="props.post.type == 'repost'">
          <NuxtLink v-if="props.post.prediction" :to="localePath('/professors-challenge/')">
            <div
              class="flex gap-3 items-center p-4 rounded-[12px]"
              :class="
                props.post.prediction.game_status == 'won' ? 'bg-primary_850' : 'bg-neutral_500'
              "
            >
              <img
                src="../../assets/img/challengeTextLogo.png"
                class="h-8 hidden sm:block"
                alt="challenge logo text variant"
              />
              <img
                src="../../assets/img/logoProfesorsChallange.png"
                class="h-16 block sm:hidden"
                alt="challenge logo"
              />
              <div class="h-[64px] sm:h-[32px] bg-primary_800 w-px" />
              <p
                class="py-[2px] text-[14px] leading-[150%] tracking-[-0.28px]"
                :class="
                  props.post.prediction.game_status == 'won'
                    ? 'text-primary_500'
                    : 'text-neutral_50'
                "
              >
                {{ props.post.prediction.game }}, {{ $t('odd') }} {{ props.post.prediction.odd }}
              </p>
              <div class="grow flex items-center justify-end">
                <p
                  v-if="props.post.prediction.game_status == 'void'"
                  class="text-[12px] sm:w-[72px] font-medium text-neutral_50 text-center"
                >
                  {{ $t('delayed') }}
                </p>
                <ClientOnly v-else-if="props.post.prediction.game_status != 'in_progress'">
                  <ion-icon
                    class="sm:w-[72px]"
                    :class="
                      props.post.prediction.game_status == 'won'
                        ? 'text-primary_500'
                        : 'text-error_400'
                    "
                    :name="
                      props.post.prediction.game_status == 'won' ? 'checkmark-sharp' : 'remove'
                    "
                  />
                </ClientOnly>
              </div>
            </div>
          </NuxtLink>
          <div v-else-if="props.post.repost">
            <Post :post="props.post.repost" place="onRepostedPost" />
          </div>
        </div>
        <UCarousel
          v-if="isPostWithManyImages"
          v-slot="{ item }"
          :items="props.post.url"
          :ui="{
            item: 'basis-full',
            indicators: {
              wrapper:
                'bg-primary_850 py-2 w-min mx-auto px-3 rounded-full bg-opacity-70 backdrop-blur'
            }
          }"
          class="caroseul mx-auto overflow-hidden mb-1 relative"
          indicators
        >
          <!-- ako treba da se skine ogranicenje sa fona samo promeniti na sm:max-h-[568px] -->
          <img
            v-pinch-zoom
            :src="item"
            class="relative max-h-[568px] rounded-xl mx-auto object-contain"
            draggable="false"
            @click="openImageZoomModal(item)"
          />
        </UCarousel>
        <NuxtLink
          v-if="isPostSingleImage && !imageError"
          :to="localePath(`/post/${props.post.id}`)"
          class="min-h-[220px]"
        >
          <!-- ako treba da se skine ogranicenje sa fona samo promeniti na sm:max-h-[568px] -->
          <img
            v-pinch-zoom
            class="relative rounded-xl mx-auto mb-1 max-h-[568px]"
            :src="props.post.url[0]"
            alt="picture related to post"
            @click="openImageZoomModal(props.post.url[0])"
            @error="imageError = true"
          />
        </NuxtLink>
        <a
          v-if="props.post.externalUrl && imageError"
          target="_blank"
          :href="props.post.externalUrl"
          class="text-primary_500 font-medium leading-[150%]"
        >
          {{ $t('clickHereToSeeTheOriginalPost') }}
        </a>
        <video
          v-if="props.post.type == 'with_video'"
          ref="videoElement"
          class="mb-1 rounded-xl max-h-[568px]"
          controls
          muted
          playsinline
        />
      </div>

      <div class="absolute inset-0 flex items-center justify-center pointer-events-none">
        <ClientOnly>
          <LottieAnimation
            v-if="showAnimation"
            :key="`animation-${animationKey}`"
            ref="reactionAnimation"
            :animation-data="currentAnimationData"
            :loop="true"
            :auto-play="true"
            @loop-complete="handleLoopCompleted"
          />
        </ClientOnly>
      </div>
    </div>
    <div class="flex items-center w-full mt-2 justify-between">
      <PostReactions
        v-if="props.place != 'onRepostedPost' && props.place != 'repostDialog'"
        ref="postReactions"
        :post="props.post"
        @reacted-to-post="emits('reacted-to-post', props.post.id)"
        @reaction-clicked="handleReactionClicked"
        @repost-clicked="isRepostDialogOpen = true"
      />
      <MobilePostComments
        v-if="
          commonStore.isMobile && props.place != 'onRepostedPost' && props.place != 'repostDialog'
        "
        :number-of-comments="props.post.totalComments"
        :post="props.post"
      />
      <!-- <a class="block sm:hidden" v-else :href="props.post.externalUrl">
          <ClientOnly>
            <ion-icon class="text-[24px] text-base_white" name="link"></ion-icon>
          </ClientOnly>
        </a> -->
      <div
        v-if="
          !showComments &&
          !commonStore.isMobile &&
          props.place != 'onRepostedPost' &&
          props.place != 'repostDialog'
        "
        class="h-10 flex items-center"
      >
        <ButtonComponent
          v-if="!totalComments"
          class="mx-auto"
          size="md"
          type="text"
          :text="`${$t('noComments')}`"
          @click="showComments = true"
        />
        <div v-else class="flex items-center gap-1 cursor-pointer" @click="showComments = true">
          <ClientOnly>
            <ion-icon class="text-[24px] text-base_white" name="chatbubble-outline" />
          </ClientOnly>
          <p class="text-[12px] font-medium text-base_white">
            {{ totalComments }}
          </p>
        </div>
      </div>
    </div>
    <div class="w-full">
      <PostComments
        v-if="!commonStore.isMobile && showComments"
        :total-comments="totalComments"
        :post="props.post"
        @close-comments="showComments = false"
        @comment-added="totalComments++"
        @comment-deleted="totalComments--"
      />
      <!-- <a v-else :href="props.post.externalUrl" target="_blank">
        <div class="hidden sm:flex items-center justify-center mt-4">
          <ButtonComponent type="outlined" cropped :text="`${$t('visitLink')}`" />
        </div>
      </a> -->
    </div>
  </div>
  <!-- <PostRepostDialog
    v-if="isRepostDialogOpen"
    :is-open="isRepostDialogOpen"
    :post="props.post"
    @close-dialog="isRepostDialogOpen = false"
    @post-reposted="handlePostReposted"
  /> -->
  <PostRepostDialogV2
    v-if="isRepostDialogOpen"
    :is-open="isRepostDialogOpen"
    :post="props.post"
    @post-reposted="handlePostReposted"
    @close-dialog="isRepostDialogOpen = false"
  />
  <PostEditDialog
    v-if="isEditDialogOpen"
    :is-open="isEditDialogOpen"
    :post="props.post"
    @close-dialog="isEditDialogOpen = false"
    @post-updated="handlePostUpdated"
  />
  <ClientOnly>
    <UDModal v-if="imageZoomModal" :is-modal-open="imageZoomModal" @close-modal="onZoomModalClose">
      <div class="w-full p-4 bg-primary_900 rounded-[12px]">
        <div class="flex flex-col gap-2">
          <div class="flex items-center h-[40px] w-full justify-end">
            <ClientOnly>
              <ion-icon
                class="text-[24px] cursor-pointer text-base_white"
                name="close"
                @click="onZoomModalClose"
              />
            </ClientOnly>
          </div>
        </div>
        <!-- zoomist-container -->
        <div class="zoomist-container">
          <!-- zoomist-wrapper is required -->
          <div class="zoomist-wrapper">
            <!-- zoomist-image is required -->
            <div class="zoomist-image">
              <!-- you can add anything you want to zoom here. -->
              <img :src="zoomImageUrl" alt="zoomed image" />
            </div>
          </div>
        </div>
      </div>
    </UDModal>
  </ClientOnly>
</template>

<script setup lang="ts">
import Zoomist from 'zoomist';
import 'zoomist/css';
import PostHeader from './PostHeader.vue';
import type { Post } from '~/interfaces';
import { useCommonStore } from '~/store/commonStore';
import Hls from 'hls.js';
import { LottieAnimation } from 'lottie-web-vue';
import { usePinchZoomDirective } from '@/composables/usePinchZoom';

const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true
  },
  showFullText: {
    type: Boolean,
    default: false
  },
  place: {
    type: String
  }
});
const emits = defineEmits(['reacted-to-post', 'post-deleted', 'post-updated']);
const commonStore = useCommonStore();
const localePath = useLocalePath();

const isTitleHovered = ref(false);
const isRepostDialogOpen = ref(false);
const postReactions = ref<any>(null);
const showComments = ref(false);
const totalComments = ref(props.post.totalComments);
const description = ref<HTMLElement | null>(null);
const isTextTruncated = ref(false);
const reactionAnimation = ref<any>(null);
const showAnimation = ref(false);
const alreadyPlayed = ref(false);
const imageError = ref(false);
const reactionType = ref<string>('positive');
const animationKey = ref<number>(0); // Add key to force re-render
const isEditDialogOpen = ref(false);
const imageZoomModal = ref(false);
const zoomImageUrl = ref('');
const videoElement = ref<HTMLVideoElement | null>(null);
let observer: IntersectionObserver | null = null;
let video: HTMLVideoElement | null = null;

const { $animation } = useNuxtApp();
const currentAnimationData = computed(() => {
  return reactionType.value === 'negativeReaction'
    ? $animation.negativeReaction
    : $animation.positiveReaction;
});

const vPinchZoom = usePinchZoomDirective().pinchZoomDirective;

onMounted(() => {
  if (props.post.type === 'with_video') {
    // promenio sam hvatanje videa preko ref umesto preko id jer je jebavalo ako se isti video pusta na dva mesta (na strani i u repost dialogu), pa da ne bih budzio id sam ovako odardio
    if (videoElement.value) {
      video = videoElement.value;
    }
    // video = document.getElementById(
    //   `video-${props.post.id}${props.place ? `-${props.place}` : ''}`
    // ) as HTMLVideoElement;
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (props.post.type === 'with_video' && Hls.isSupported()) {
              playVideo();
            }
          } else {
            stopVideo();
          }
        });
      },
      {
        threshold: 0.5
      }
    );
    if (video) {
      observer.observe(video);
    }
  }
  checkTruncation();
});

const playVideo = () => {
  if (video) {
    if (alreadyPlayed.value) {
      video.play();
    } else {
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = props.post.url[0];
        video.addEventListener('loadedmetadata', () => {
          video?.play();
          alreadyPlayed.value = true;
        });
      } else {
        const hls = new Hls();
        hls.loadSource(props.post.url[0]);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video?.play();
          alreadyPlayed.value = true;
        });
      }
    }
  }
};

const stopVideo = () => {
  video?.pause();
};

const handleReactionClicked = (reaction: string) => {
  reactionType.value = reaction;
  animationKey.value++; // Increment key to force re-render
  showAnimation.value = true;
};

const handleLoopCompleted = () => {
  if (reactionAnimation.value) {
    reactionAnimation.value.stop();
    showAnimation.value = false;
  }
};
const checkTruncation = () => {
  if (description.value && description.value.scrollHeight > description.value.clientHeight) {
    isTextTruncated.value = true;
  }
};
const handlePostReposted = () => {
  if (postReactions.value) {
    postReactions.value.increaseNumberOfReposts();
  }
};
onUnmounted(() => {
  if (observer && video) {
    observer.unobserve(video);
    observer.disconnect();
  }
  if (reactionAnimation.value) {
    reactionAnimation.value.destroy();
  }
});

const isPostSingleImage = computed(() => {
  return props.post.url && props.post.url.length === 1 && props.post.type !== 'with_video';
});

const isPostWithManyImages = computed(() => {
  return props.post.url && props.post.url.length > 1 && props.post.type !== 'with_video';
});

const handlePostUpdated = (updatedPost: Post) => {
  emits('post-updated', updatedPost);
};

const openImageZoomModal = (imageUlr: string) => {
  if (props.showFullText && !commonStore.isMobile) {
    imageZoomModal.value = true;
    zoomImageUrl.value = imageUlr;

    // TODO: promeniti ovo kada se prebaci image zoom u komponentu
    setTimeout(() => {
      new Zoomist('.zoomist-container', {
        maxScale: 4,
        initScale: commonStore.isMobile ? 1.3 : 1.5,
        slider: false,
        zoomer: !commonStore.isMobile
      });
    }, 200);
  }
};

const onZoomModalClose = () => {
  imageZoomModal.value = false;
  zoomImageUrl.value = '';
};
</script>

<style>
.truncate-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.zoomist-container {
  width: 100%;
  height: 100%;
}

.zoomist-wrapper {
  background-color: transparent;
}

.zoomist-image {
  width: 100%;
  aspect-ratio: 1;
}

.zoomist-image img {
  width: 100%;
  height: auto;
  max-height: 548px;
  object-fit: contain;
  object-position: center;
}
</style>
