import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
  const options = {
    siteKey: nuxtApp.$config.public.recaptchaV3SiteKey,
    loaderOptions: {
      autoHideBadge: true
    }
  };
  nuxtApp.vueApp.use(VueReCaptcha, options);
});
