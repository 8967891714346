import { useI18n } from '#imports';
export function useTimeFunctions() {
  const { t } = useI18n();
  const calculateTimeAgo = (timeOfCreation: string) => {
    const date = new Date(timeOfCreation);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const intervals = [
      { label: 's', seconds: 1 },
      { label: 'min', seconds: 60 },
      { label: 'h', seconds: 3600 },
      { label: 'd', seconds: 86400 }
    ];

    for (let i = intervals.length - 1; i >= 0; i--) {
      const interval = intervals[i];
      const count = Math.floor(seconds / interval.seconds);

      if (count >= 1) {
        return t('timeAgo', { count, label: interval.label });
      }
    }

    return t('justNow');
  };
  const formatDateFromISO = (dateToFormat: string): string => {
    const date = new Date(dateToFormat);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`; // 11.12.2023.
  };

  const calculateDifferenceInSeconds = (firstDateString: string, secondDateString?: string) => {
    const firstDate = new Date(firstDateString);
    const secondDate = secondDateString ? new Date(secondDateString) : new Date();
    return Math.abs(firstDate.getTime() - secondDate.getTime()) / 1000;
  };

  const formatDateInPredictionFormat = (dateToFormat: string): string => {
    const date = new Date(dateToFormat);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}. | ${hours}:${minutes}`; // 11.12. | 13:29
  };
  const formatDateToDayMonthFormat = (dateToFormat: string) => {
    const date = new Date(dateToFormat);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');

    return `${day}.${month}.`; // 11.12.
  };

  return {
    calculateTimeAgo,
    formatDateFromISO,
    calculateDifferenceInSeconds,
    formatDateInPredictionFormat,
    formatDateToDayMonthFormat
  };
}
