<template>
  <div class="p-4 sm:p-6 bg-primary_900 sm:border border-primary_500 rounded sm:rounded-[8px]">
    <textarea
      ref="commentTextarea"
      :value="internalValue"
      :rows="commonStore.isMobile ? 2 : 5"
      class="w-full outline-none bg-transparent placeholder:text-neutral_200 text-base_white text-base font-medium leading-[150%] tracking-[-0.32px]"
      :placeholder="`${$t('writeComment')}`"
      @input="updateValue($event.target as HTMLInputElement)"
    />
    <div class="flex items-center justify-between w-full mt-2">
      <ClientOnly>
        <EmojiPicker v-if="!commonStore.isMobile" @emoji-selected="handleEmojiSelected" />
      </ClientOnly>
      <div class="flex items-center gap-3 justify-between sm:justify-end w-full">
        <ButtonComponent
          :type="commonStore.isMobile ? 'text' : 'outlined'"
          :text="`${$t('giveUp')}`"
          :text-size="commonStore.isMobile ? 'text-[12px]' : 'text-[14px]'"
          :font-thickness="commonStore.isMobile ? 'font-medium' : 'font-bold'"
          :text-color="commonStore.isMobile ? 'text-neutral_200' : ''"
          @click="emit('hide-textarea')"
        />
        <ButtonComponent
          :is-loading="props.isDoingService"
          :type="commonStore.isMobile ? 'text' : 'filled'"
          :text="`${$t('shareComment')}`"
          :text-size="commonStore.isMobile ? 'text-[12px]' : 'text-[14px]'"
          :font-thickness="commonStore.isMobile ? 'font-medium' : 'font-bold'"
          :text-color="commonStore.isMobile ? 'text-neutral_200' : ''"
          @click="createComment"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '~/store/commonStore';
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  isDoingService: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:modelValue', 'create-comment', 'hide-textarea']);
const internalValue = ref(props.modelValue);
const commonStore = useCommonStore();
const commentTextarea = ref<HTMLTextAreaElement | null>(null);
watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);
const updateValue = (target: HTMLInputElement | null) => {
  if (target) {
    const newValue: string = target.value;
    internalValue.value = newValue;
    emit('update:modelValue', newValue);
  }
};
const createComment = () => {
  if (internalValue.value) {
    emit('create-comment');
    // internalValue.value = '';
  }
};
const focusTextarea = () => {
  if (commentTextarea.value) {
    commentTextarea.value.focus();
  }
};
const handleEmojiSelected = (emoji: any) => {
  internalValue.value += emoji;
  emit('update:modelValue', internalValue.value);
};
defineExpose({ focusTextarea });
</script>
<style scoped>
textarea {
  resize: none;
}
</style>
