<script setup lang="ts">
import { state } from '@/socket';

const isConnected = ref(false);

isConnected.value = state.connected;
if (isConnected.value) {
  console.log('Socket client connected');
}
</script>

<template>
  <div style="display: none" />
</template>
