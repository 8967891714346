<template>
  <TransitionRoot as="template" :show="dialogStore.postDialog.isOpen">
    <Dialog class="relative z-10" @close="dialogStore.closePostDialog">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              v-show="post"
              class="relative transform overflow-hidden w-full max-w-[755px] rounded-lg bg-neutral_950 p-6"
            >
              <Post
                v-if="post"
                :post="post"
                @reacted-to-post="getThisPost"
                @post-updated="handlePostUpdated"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { postRepository } from '~/repositories/postRepository';
import type { Post } from '~/interfaces';
import { useDialogStore } from '~/store/dialogStore';

const { $api } = useNuxtApp();
const { t } = useI18n();
const postRepo = postRepository($api);
const post = ref<Post | null>(null);
const dialogStore = useDialogStore();

const getThisPost = async () => {
  try {
    post.value = await postRepo.getPost(dialogStore.postDialog.postId);
  } catch (err) {
    dialogStore.closePostDialog();
    nextTick(() => {
      dialogStore.createConfirmationDialog(
        `${t('notification')}`,
        `${t('postDoesntExist')}`,
        `${t('ok')}`
      );
    });
  }
};

const handlePostUpdated = (updatedPost: Post) => {
  post.value = updatedPost;
};

onMounted(async () => {
  getThisPost();
});
</script>
