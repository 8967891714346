<template>
  <div class="flex items-center">
    <img
      v-for="i in Math.round(rating)"
      :key="i"
      :class="sizeClasses[props.size]"
      src="../../assets/img/fullBone.svg"
      alt="picture representing positive rating"
    />
    <img
      v-for="i in 5 - Math.round(rating)"
      :key="i"
      :class="sizeClasses[props.size]"
      src="../../assets/img/emptyBone.svg"
      alt="picture representing negative rating"
    />
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  rating: {
    type: Number,
    required: true
  },
  size: {
    type: String as PropType<Size>,
    default: 'md'
  }
});
type Size = 'sm' | 'md' | 'lg';

const sizeClasses = {
  sm: 'h-[12px]',
  md: 'h-[14px]',
  lg: 'h-[20px]'
};
</script>
