type ActionFunction = () => void;
type NotificationState = 'success' | 'warning' | 'error';

export const useDialogStore = defineStore({
  id: 'dialogStore',
  state: () => ({
    confirmationDialog: {
      isOpen: false,
      title: '',
      message: '',
      confirmButtonText: '',
      cancelButtonText: '' as string | undefined,
      onConfirm: null as ActionFunction | null | undefined,
      onCancel: null as ActionFunction | null | undefined,
      isPersistent: false as boolean | undefined
    },
    toasterNotification: {
      showNotification: false,
      notificationType: 'success' as NotificationState,
      notificationTitle: '',
      notificationText: '',
      duration: 3000
    },
    postDialog: {
      isOpen: false,
      postId: 0
    },
    loginDialog: {
      isOpen: false
    }
  }),
  actions: {
    createConfirmationDialog(
      title: string,
      message: string,
      confirmButtonText: string,
      cancelButtonText?: string,
      onConfirm?: ActionFunction | null,
      onCancel?: ActionFunction | null,
      isPersistent?: boolean | undefined
    ) {
      this.confirmationDialog = {
        isOpen: true,
        title,
        message,
        confirmButtonText,
        cancelButtonText,
        onConfirm,
        onCancel,
        isPersistent
      };
    },
    closeConfirmationDialog() {
      this.confirmationDialog.isOpen = false;
    },
    createToasterNotification(
      type: NotificationState,
      title: string,
      text: string,
      duration?: number
    ) {
      this.toasterNotification.showNotification = true;
      this.toasterNotification.notificationType = type;
      this.toasterNotification.notificationTitle = title;
      this.toasterNotification.notificationText = text;
      this.toasterNotification.duration = duration ? duration : 3000;
      setTimeout(() => {
        this.toasterNotification.showNotification = false;
      }, this.toasterNotification.duration);
    },
    closeToasterNotification() {
      this.toasterNotification.showNotification = false;
    },
    createPostDialog(postId: number) {
      this.postDialog.isOpen = true;
      this.postDialog.postId = postId;
    },
    closePostDialog() {
      this.postDialog.isOpen = false;
      this.postDialog.postId = 0;
    },
    createLoginDialog() {
      this.loginDialog.isOpen = true;
    },
    closeLoginDialog() {
      this.loginDialog.isOpen = false;
    }
  }
});
