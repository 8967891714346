export function usePinchZoomDirective() {
  const pinchZoomDirective = {
    mounted(el: HTMLElement) {
      let imageElementScale = 1;
      const imageElement = el;

      const start: { x: number; y: number; distance: number } = { x: 0, y: 0, distance: 0 };

      // Calculate distance between two fingers
      const distance = (event: TouchEvent) => {
        return Math.hypot(
          event.touches[0].pageX - event.touches[1].pageX,
          event.touches[0].pageY - event.touches[1].pageY
        );
      };

      const touchStartHandler = (event: TouchEvent) => {
        if (event.touches.length === 2) {
          event.preventDefault(); // Prevent page scroll

          // Calculate where the fingers have started on the X and Y axis
          start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
          start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
          start.distance = distance(event);
        }
      };

      const touchMoveHandler = (event: TouchEvent) => {
        if (event.touches.length === 2) {
          event.preventDefault(); // Prevent page scroll
          let scale;

          // Safari provides event.scale as two fingers move on the screen
          // For other browsers just calculate the scale manually
          if (event.scale) {
            scale = event.scale;
          } else {
            const deltaDistance = distance(event);
            scale = deltaDistance / start.distance;
          }

          imageElementScale = Math.min(Math.max(1, scale), 4);

          // Calculate how much the fingers have moved on the X and Y axis
          const deltaX = ((event.touches[0].pageX + event.touches[1].pageX) / 2 - start.x) * 2; // x2 for accelarated movement
          const deltaY = ((event.touches[0].pageY + event.touches[1].pageY) / 2 - start.y) * 2; // x2 for accelarated movement

          // Transform the image to make it grow and move with fingers
          const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`;
          imageElement.style.transform = transform;
          imageElement.style.transform = transform;
          imageElement.style.zIndex = '9999';
        }
      };

      const touchEndHandler = () => {
        el.style.position = 'relative';
        el.style.transform = '';
        el.style.zIndex = '';
      };

      el.addEventListener('touchstart', touchStartHandler);
      el.addEventListener('touchmove', touchMoveHandler);
      el.addEventListener('touchend', touchEndHandler);
    }
  };

  return {
    pinchZoomDirective
  };
}
