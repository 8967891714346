interface scrollPositionOject {
  positionNumber: number;
  numberOfServiceCalls: number;
}
export const useCommonStore = defineStore({
  id: 'commonStore',
  state: () => ({
    currentWidth: import.meta.client ? window.innerWidth : 0,
    shouldReloadPosts: false,
    isCompetitionFaqOpen: false,
    scrollPositions: {} as Record<string, scrollPositionOject>
  }),
  actions: {
    setCurrentWidth(newWidth: number) {
      this.currentWidth = newWidth;
    },
    setShouldReloadPosts(newValue: boolean) {
      this.shouldReloadPosts = newValue;
    },
    setIsCompetitionFaqOpen(newValue: boolean) {
      this.isCompetitionFaqOpen = newValue;
    },
    setScrollPosition(routeName: string, position: number, numberOfServiceCalls?: number) {
      this.scrollPositions[routeName] = {
        positionNumber: position,
        numberOfServiceCalls: numberOfServiceCalls || 0
      };
    }
  },
  getters: {
    isMobile: (state) => state.currentWidth && state.currentWidth < 481,
    isTablet: (state) => state.currentWidth && state.currentWidth < 745,
    isSmallDevice: (state) => state.currentWidth && state.currentWidth < 1025
  }
});
