import { useOneSignal } from '@onesignal/onesignal-vue3';

export default defineNuxtPlugin((nuxtApp) => {
  const appId = nuxtApp.$config.public.onesignalAppId as string;

  if (!appId) {
    return;
  }

  const OneSignal = useOneSignal();

  OneSignal.init({
    appId,
    allowLocalhostAsSecureOrigin: process.env.SITE_ENV !== 'production'
  });

  return {
    provide: {
      OneSignal
    }
  };
});
