<template>
  <div :class="props.comment.parentCommentId ? 'border-l border-primary_800 pl-2' : ''">
    <div class="flex items-center gap-2 pt-2">
      <UserPicture color="text-neutral_50" gap="gap-2" :user="props.comment.user" />
      <div class="flex flex-col gap-1 flex-grow">
        <div class="flex items-center gap-2">
          <div class="w-px h-[21px] bg-primary_900" />
          <p class="text-[12px] font-medium leading-[150%] text-neutral_200">
            {{ calculateTimeAgo(props.comment.created_at) }}
          </p>
        </div>
      </div>
      <div class="flex gap-8 items-center">
        <SettingsPopover
          v-if="(userId && props.comment.user.id == userId) || canDeleteComment"
          :actions="actions"
          :flex-row-on-small-devices="false"
          class="-mb-2"
        />
        <div class="flex flex-col items-center">
          <ClientOnly>
            <ion-icon
              class="text-[24px] text-neutral_200 cursor-pointer"
              name="heart-outline"
              @click="reactToComment"
            />
          </ClientOnly>
          <p class="text-[12px] font-medium text-base_white">{{ numberOfPositiveReactions }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <p
        class="px-3 text-[14px] break-words sm:text-base font-medium leading-[150%] tracking-[-0.28px] sm:tracking-[-0.32px] text-neutral_50"
      >
        {{ props.comment.text }}
      </p>
      <div class="flex items-center">
        <ButtonComponent
          v-if="props.comment.parentCommentId == null"
          type="text"
          :text="`${$t('reply')}`"
          text-color="text-neutral_200"
          :text-size="commonStore.isMobile ? 'text-[12px]' : 'text-[14px]'"
          font-thickness="font-medium"
          @click="handleReplyClicked"
        />
        <div
          v-if="props.comment.childComments && props.comment.childComments.length"
          class="w-px h-[24px] bg-primary_900"
        />
        <ButtonComponent
          v-if="props.comment.childComments && props.comment.childComments.length"
          :text="
            showReplies
              ? `${$t('hideReplies')}`
              : `${$t('showReplies', { number: props.comment.childComments.length })}`
          "
          type="text"
          text-color="text-neutral_200"
          :text-size="commonStore.isMobile ? 'text-[12px]' : 'text-[14px]'"
          font-thickness="font-medium"
          @click="toggleReplies"
        />
      </div>
      <LeaveComment
        v-if="showLeaveComment"
        v-model="replyText"
        :is-doing-service="isLoading"
        class="mt-4"
        @hide-textarea="showLeaveComment = false"
        @create-comment="createReply"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import UserPicture from '../User/UserPicture.vue';
import { postRepository } from '~/repositories/postRepository';
import type { Comment, settingsItem } from '~/interfaces';
import { useDialogStore } from '~/store/dialogStore';
import { useUserStore } from '~/store/user';
import { useCommonStore } from '~/store/commonStore';
const props = defineProps({
  comment: {
    type: Object as PropType<Comment>,
    required: true
  }
});
const emits = defineEmits(['show-replies', 'created-reply', 'delete-comment', 'reply-opened']);
const { calculateTimeAgo } = useTimeFunctions();
const { $api, $ability } = useNuxtApp();
const postRepo = postRepository($api);
const dialogStore = useDialogStore();
const commonStore = useCommonStore();
const userStore = useUserStore();
const userId = userStore.user?.id;
const { t } = useI18n();
const showLeaveComment = ref(false);
const isLoading = ref(false);
const showReplies = ref(false);
const numberOfPositiveReactions = ref(props.comment.positiveReaction);
const alreadyLikedComment = ref(false);
const replyText = ref('');
const createReply = async () => {
  try {
    isLoading.value = true;
    const response = await postRepo.createComment(
      replyText.value,
      props.comment.postId,
      props.comment.id
    );
    isLoading.value = false;
    showLeaveComment.value = false;
    emits('created-reply', response);
  } catch (err) {
    console.log(err);
  }
};
const toggleReplies = () => {
  showReplies.value = !showReplies.value;
  emits('show-replies', showReplies.value, props.comment.id);
};
const reactToComment = async () => {
  try {
    if (alreadyLikedComment.value && userId) {
      throw new Error('Already liked comment');
    }
    alreadyLikedComment.value = true;
    await postRepo.reactToComment(props.comment.id);
    numberOfPositiveReactions.value++;
  } catch (err: any) {
    console.log(err);
    if (!(err.statusCode && err.statusCode == 401)) {
      dialogStore.createToasterNotification(
        'warning',
        `${t('notification')}`,
        `${t('alreadyReactedToComment')}`
      );
    }
  }
};
const handleReplyClicked = () => {
  showLeaveComment.value = !showLeaveComment.value;
  if (showLeaveComment.value) {
    emits('reply-opened');
  }
};
const deleteComment = async () => {
  try {
    await postRepo.deleteComment(props.comment.id);
    emits('delete-comment');
  } catch (err) {
    console.log(err);
  }
};
const askForPermissionOnDelete = () => {
  dialogStore.createConfirmationDialog(
    `${t('confirmation')}`,
    `${t('deleteCommentQuestion')}`,
    `${t('yes')}`,
    `${t('no')}`,
    deleteComment
  );
};
const actions: settingsItem[] = [
  {
    text: `${t('commentDelete')}`,
    action: askForPermissionOnDelete
  }
];

const canDeleteComment = ref($ability.value.can('delete', 'Comment'));
</script>
