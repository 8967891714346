<template>
  <dialog
    v-if="props.isModalOpen"
    @keydown="handleKeyPress"
    @animationend="handleAnimationEnd"
    @click="closeModalOnOutsideClick"
    ref="modalRef"
    class="outline-none bg-transparent w-full sm:max-w-[622px]"
  >
    <div class="overflow-y-hidden">
      <slot></slot>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
const emits = defineEmits(['close-modal']);

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    required: true
  }
});
const modalRef = ref<HTMLDialogElement | null>(null);
const openModal = () => {
  modalRef.value?.showModal();
  document.body.style.overflow = 'hidden';
};
const closeModal = () => {
  returnScrollToTheBodyIfNoDialogs();
  modalRef.value?.close();
};
const closeModalOnOutsideClick = (e: MouseEvent) => {
  const modalDimensions = modalRef.value?.getBoundingClientRect();
  if (e.detail == 0) return;
  if (modalDimensions) {
    if (
      e.clientX < modalDimensions.left ||
      e.clientX > modalDimensions.right ||
      e.clientY < modalDimensions.top ||
      e.clientY > modalDimensions.bottom
    ) {
      modalRef.value?.setAttribute('closing', '');
    }
  }
};
const handleAnimationEnd = (e: AnimationEvent) => {
  if (e.animationName.includes('fade-out')) {
    modalRef.value?.removeAttribute('closing');
    emits('close-modal');
    closeModal();
  }
};
const handleKeyPress = (e: KeyboardEvent) => {
  if (e.key == 'Escape') {
    document.body.style.overflow = 'auto';
    emits('close-modal');
  }
};
const returnScrollToTheBodyIfNoDialogs = () => {
  const allDialogs = document.querySelectorAll('dialog');
  if (allDialogs.length == 1) {
    document.body.style.overflow = 'auto';
  }
};
onMounted(() => {
  if (props.isModalOpen) {
    openModal();
  }
});
onBeforeUnmount(() => {
  returnScrollToTheBodyIfNoDialogs();
});
watch(
  () => props.isModalOpen,
  (newValue) => {
    newValue ? openModal() : modalRef.value?.setAttribute('closing', '');
  }
);
</script>
<style scoped>
dialog::backdrop {
  background: rgba(14, 20, 5, 0.6);
  opacity: 0;
}
@supports (-moz-appearance: none) {
  dialog::backdrop {
    opacity: 1;
  }
}
dialog[open] {
  animation: fade-in 200ms forwards;
}

dialog[closing] {
  display: block;
  pointer-events: none;
  animation: fade-out 200ms forwards;
}
dialog[open]::backdrop {
  animation: fade-in 200ms forwards;
}
dialog[closing]::backdrop {
  animation: fade-out 200ms forwards;
}
.disableScroll {
  overflow-y: hidden;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
