<template>
  <div class="flex items-center gap-4 h-[40px] sm:h-auto">
    <div class="flex items-center gap-[6px] sm:gap-1">
      <div
        class="flex flex-col justify-end relative cursor-pointer"
        @click="reactToPost('positiveReaction')"
      >
        <img
          src="/assets/img/vatrica.svg"
          alt="picture for like action"
          class="w-[28px] sm:w-[32px] animated-icon"
        />
        <!-- <img
          class="absolute bottom-0 left-0 w-[19.2px] sm:w-[25.6px]"
          :class="isLikeHovered ? 'animate-ping' : ''"
          src="/assets/img/vatrica.svg"
          alt="picture for like action"
        /> -->
      </div>
      <p class="text-[12px] text-base_white">
        {{ positiveReaction }}
      </p>
    </div>
    <div class="flex items-center gap-[6px] sm:gap-1">
      <div class="cursor-pointer" @click="reactToPost('negativeReaction')">
        <img
          src="/assets/img/pljuca.svg"
          alt="picture for dislike action"
          class="w-[28px] sm:w-[32px] animated-icon"
        />
      </div>
      <p class="text-[12px] text-base_white font-medium">
        {{ negativeReaction }}
      </p>
    </div>
    <div
      v-if="!(props.post.type == 'repost' && props.post.repost) && !props.post.shortCompetitionPost"
      class="flex items-center gap-[6px] sm:gap-1"
    >
      <ClientOnly>
        <ion-icon
          class="text-[24px] cursor-pointer text-base_white"
          name="repeat-outline"
          @click="emits('repost-clicked')"
        />
      </ClientOnly>
      <p class="text-[12px] text-base_white font-medium">{{ numberOfReposts }}</p>
    </div>
    <ClientOnly>
      <SettingsPopover
        icon="share-social-outline"
        size="text-[24px] mt-2"
        :position="commonStore.isMobile ? ' bottom-10 left-[-96px]' : ' top-10 right-[-28px]'"
        :actions="popoverActions"
      />
    </ClientOnly>
    <!-- <div class="h-px bg-primary_800 w-full"></div>
      <ion-icon
        class="text-[32px] cursor-pointer text-base_white"
        name="repeat-outline"
      ></ion-icon>
      <p
        class="text-base font-medium leading-[150%] tracking-[-0.32px] text-base_white"
      >
        Repost
      </p> -->
  </div>
</template>
<script lang="ts" setup>
import type { Post, settingsItem } from '~/interfaces';
import { postRepository } from '~/repositories/postRepository';
import { useDialogStore } from '~/store/dialogStore';
import { useCommonStore } from '~/store/commonStore';
import { usePostShare } from '~/composables/usePostShare';
const { debounce } = useGlobalFunctions();

const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true
  }
});
const emits = defineEmits(['reaction-clicked', 'repost-clicked']);
const numberOfReposts = ref(props.post.repostCount);
const dialogStore = useDialogStore();
const { $api } = useNuxtApp();
const postRepo = postRepository($api);
// const isLikeHovered = ref(false);
const previousAction = ref('');
const commonStore = useCommonStore();

const positiveReaction = ref(props.post.positiveReaction);
const negativeReaction = ref(props.post.negativeReaction);

// Prati promene na postu da bi update lokalne promenljive za reakcije
watch(
  () => props.post,
  (newPost) => {
    positiveReaction.value = newPost.positiveReaction;
    negativeReaction.value = newPost.negativeReaction;
  },
  { immediate: true, deep: true }
);

const reactToPost = debounce(async (reactionType: string) => {
  try {
    if (reactionType == 'positiveReaction') {
      // ako je vec glasao isto baci error
      if (previousAction.value === 'positiveReaction') {
        throw new Error('User has already liked post');
      }
      // ako je prethodno dislike smanji negativne za 1
      if (previousAction.value === 'negativeReaction') {
        negativeReaction.value -= 1;
      }
      // povecaj broj za jedan i posalji event za animaciju
      await postRepo.reactToPost(props.post.id, reactionType);
      positiveReaction.value += 1;
      emits('reaction-clicked', 'positiveReaction');
      previousAction.value = reactionType;
    } else if (reactionType == 'negativeReaction') {
      if (previousAction.value === 'negativeReaction') {
        throw new Error('User has already disliked post');
      }
      if (previousAction.value === 'positiveReaction') {
        positiveReaction.value -= 1;
      }
      await postRepo.reactToPost(props.post.id, reactionType);
      negativeReaction.value += 1;
      emits('reaction-clicked', 'negativeReaction');
      previousAction.value = reactionType;
    }
  } catch (err: any) {
    if (err.response && err.response.status === 403) {
      showNotificationIfUserAlreadyReacted();
    }
  }
}, 500);

const showNotificationIfUserAlreadyReacted = () => {
  dialogStore.createToasterNotification('warning', 'Upozorenje', 'Veċ ste reagovali na ovu objavu');
};

let popoverActions: settingsItem[] = [];

if (import.meta.client) {
  const { shareFacebook, shareTwitter, shareWhatsapp, shareReddit, copyUrl } = usePostShare(
    props.post
  );

  popoverActions = [
    {
      icon: 'copy-outline',
      text: '',
      action: copyUrl
    },
    {
      icon: 'logo-facebook',
      text: '',
      action: shareFacebook
    },
    {
      icon: 'logo-twitter',
      text: '',
      action: shareTwitter
    },
    {
      icon: 'logo-reddit',
      text: '',
      action: shareReddit
    },
    {
      icon: 'logo-whatsapp',
      text: '',
      action: shareWhatsapp
    }
  ];
}
const increaseNumberOfReposts = () => {
  numberOfReposts.value++;
};
defineExpose({ increaseNumberOfReposts });
</script>

<style scoped>
.animated-icon {
  display: inline-block;
  transform: scale(1);
}

.animated-icon:hover {
  animation: shrink-grow-shrink 0.4s ease-in-out forwards;
}

@keyframes shrink-grow-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.75);
  }
}
</style>
