import type { User } from '~/interfaces';

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null);
  const weekNumberOfPredictions = ref(0);
  const monthNumberOfPredictions = ref(0);

  function setUser(userData: User | null) {
    user.value = userData;
  }
  function setUserPicture(pictureUrl: string) {
    if (user.value) {
      user.value.picture = pictureUrl;
    }
  }
  function setWeekNumberOfPredictions(newNumberOfPredictions: number) {
    weekNumberOfPredictions.value = newNumberOfPredictions;
  }
  function setMontNumberOfPredictions(newNumberOfPredictions: number) {
    monthNumberOfPredictions.value = newNumberOfPredictions;
  }
  function increaseWeekNumberOfPredictions() {
    weekNumberOfPredictions.value++;
  }
  function increaseMonthNumberOfPredictions() {
    monthNumberOfPredictions.value++;
  }
  function decreaseWeekNumberOfPredictions() {
    weekNumberOfPredictions.value--;
  }
  function decreaseMontNumberOfPredictions() {
    monthNumberOfPredictions.value--;
  }
  function setIsUserBlockedFromCompetition(newValue: boolean) {
    if (user.value) {
      user.value.isCompetitionBlocked = newValue;
    }
  }
  function getUserAbilities() {
    if (user.value) {
      return user.value.role.permissions;
    }
  }
  function setUserUsername(newUsername: string) {
    if (user.value) {
      user.value.username = newUsername;
    }
  }
  return {
    user,
    weekNumberOfPredictions,
    monthNumberOfPredictions,
    setUser,
    setUserPicture,
    setWeekNumberOfPredictions,
    setMontNumberOfPredictions,
    increaseWeekNumberOfPredictions,
    increaseMonthNumberOfPredictions,
    decreaseMontNumberOfPredictions,
    decreaseWeekNumberOfPredictions,
    setIsUserBlockedFromCompetition,
    getUserAbilities,
    setUserUsername
  };
});
