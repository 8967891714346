<template>
  <TransitionRoot as="template" :show="dialogStore.loginDialog.isOpen">
    <Dialog class="relative z-30" @close="dialogStore.closeLoginDialog">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-30 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden w-full max-w-[340px] shadow-xl rounded-lg bg-neutral_700 p-6"
            >
              <div class="flex flex-col items-center gap-4">
                <form class="flex flex-col items-center gap-4" @submit.prevent="onLogin">
                  <img
                    src="../../assets/img/framedLogo.svg"
                    class="block md:hidden"
                    alt="logo of underdogz"
                  />
                  <p class="text-[28px] mt-2 md:mt-8 font-bold leading-[120%] text-neutral_50">
                    {{ t('login') }}
                  </p>
                  <div class="flex items-center gap-6">
                    <p class="text-[14px] text-center font-medium leading-[150%] text-neutral_50">
                      {{ t('loginText') }}
                    </p>
                  </div>
                  <NuxtLink :to="localePath('/register')">
                    <ButtonComponent type="text" :cropped="true" :text="t('registerIn')" />
                  </NuxtLink>
                  <InputText
                    v-model="email"
                    size="medium"
                    placeholder-text="Email"
                    :error="v$.email.$error"
                    :error-messages="v$.email.$errors"
                  />
                  <InputText
                    v-model="password"
                    size="medium"
                    type="password"
                    placeholder-text="Password"
                    :error="v$.password.$error"
                    :error-messages="v$.password.$errors"
                  />

                  <ButtonComponent
                    class="flex mt-3"
                    :is-loading="isLoading"
                    :text="t('login')"
                    size="md"
                    :form-element="true"
                  />
                </form>
                <p
                  v-if="loginError"
                  class="text-error_400 text-center font-medium text-[14px] leading-[150%] tracking-[-0.28px]"
                >
                  {{ loginError }}
                </p>
                <div class="flex flex-col items-center gap-3">
                  <p
                    class="text-[14px] font-medium leading-[150%] tracking-[-0.28px] text-neutral_50 text-center"
                  >
                    {{ t('googleLoginText') }}
                    {{ t('googleAccount') }}
                  </p>
                  <div class="h-px w-full bg-primary_800" />
                  <div
                    class="flex items-center justify-center w-10 h-10 rounded-full bg-neutral_400 cursor-pointer"
                  >
                    <ClientOnly>
                      <ion-icon
                        class="text-[24px] text-neutral_50"
                        name="logo-google"
                        @click="onGoogleLogin"
                      />
                    </ClientOnly>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useDialogStore } from '~/store/dialogStore';
import useVuelidate from '@vuelidate/core';
import { required, email as emailValidator, helpers } from '@vuelidate/validators';

const dialogStore = useDialogStore();
const { t } = useI18n();
const email = ref('');
const password = ref('');
const loginError = ref('');
const isLoading = ref(false);
const localePath = useLocalePath();

const { $event } = useNuxtApp();

const rules = {
  email: {
    required: helpers.withMessage('Email je obavezan', required),
    emailValidator: helpers.withMessage('Email mora biti u ispravnom formatu', emailValidator)
  },
  password: { required: helpers.withMessage('Lozinka je obavezna', required) }
};

const v$ = useVuelidate(rules, { email, password });

const { login, googleLogin } = useLogin();

const onLogin = async () => {
  try {
    const isFormValid = await v$.value.$validate();
    if (isFormValid) {
      isLoading.value = true;
      const user = await login(email.value, password.value);
      isLoading.value = false;
      $event('user:loginFromDialog', user);
      useTrackEvent('login', {
        method: 'Manually'
      });
      if (user) {
        loginError.value = '';
        dialogStore.closeLoginDialog();
      }
    }
  } catch (err) {
    isLoading.value = false;
    loginError.value = t('loginError');
  }
};

const onGoogleLogin = async () => {
  useTrackEvent('login', {
    method: 'Google'
  });
  await googleLogin();
};
</script>
