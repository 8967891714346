import { defineNuxtPlugin } from '#app';

interface Animations {
  positiveReaction: any;
  negativeReaction: any;
}

export default defineNuxtPlugin(async () => {
  const positiveReaction = await import('~/assets/animations/positivanimation.json');
  const negativeReaction = await import('~/assets/animations/negativanimation.json');

  const $animation: Animations = {
    positiveReaction: positiveReaction.default,
    negativeReaction: negativeReaction.default
  };

  return {
    provide: {
      animation: $animation
    }
  };
});
