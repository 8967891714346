import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_LDumGYo2KH from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import abilities_client_LbBsVsd0hC from "/app/plugins/abilities.client.ts";
import animations_client_ssQko44Fbf from "/app/plugins/animations.client.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import clickOutside_client_Zc9fkH3Rh6 from "/app/plugins/clickOutside.client.ts";
import event_tn7VypN9eg from "/app/plugins/event.ts";
import locally_x7WzP2yX7w from "/app/plugins/locally.ts";
import nuxtServerInit_eBqFsnP1jf from "/app/plugins/nuxtServerInit.ts";
import onesignal_client_FpFKSicGgh from "/app/plugins/onesignal.client.ts";
import recaptchaV2_client_Cxm5qQ4fA7 from "/app/plugins/recaptchaV2.client.ts";
import recaptchaV3_CGmWGkOwU6 from "/app/plugins/recaptchaV3.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  i18n_yfWm7jX06p,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  abilities_client_LbBsVsd0hC,
  animations_client_ssQko44Fbf,
  api_GFfDXud5Cr,
  clickOutside_client_Zc9fkH3Rh6,
  event_tn7VypN9eg,
  locally_x7WzP2yX7w,
  nuxtServerInit_eBqFsnP1jf,
  onesignal_client_FpFKSicGgh,
  recaptchaV2_client_Cxm5qQ4fA7,
  recaptchaV3_CGmWGkOwU6
]