import type { Post } from '~/interfaces';
import { useDialogStore } from '~/store/dialogStore';

export function usePostShare(post: Post) {
  const baseUrl = window.location.origin;
  const dialogStore = useDialogStore();
  const { t } = useI18n();

  const buildShareUrl = (network: string) => {
    return {
      network: network,
      url: `${baseUrl}/post/${post.id}`,
      title: post.postTitle ? post.postTitle : post.postDescription,
      image: post.type === 'with_image' && post.url.length ? post.url[0] : ''
    };
  };

  const shareFacebook = () => {
    const options = buildShareUrl('facebook');
    const selectedNetwork = useSocialShare(options);
    window.open(selectedNetwork.value.shareUrl, '_blank', 'noopener,noreferrer');
  };

  const shareTwitter = () => {
    const options = buildShareUrl('twitter');
    const selectedNetwork = useSocialShare(options);
    window.open(selectedNetwork.value.shareUrl, '_blank', 'noopener,noreferrer');
  };

  const shareWhatsapp = () => {
    const options = buildShareUrl('whatsapp');
    const selectedNetwork = useSocialShare(options);
    window.open(selectedNetwork.value.shareUrl, '_blank', 'noopener,noreferrer');
  };

  const shareReddit = () => {
    const options = buildShareUrl('reddit');
    const selectedNetwork = useSocialShare(options);
    window.open(selectedNetwork.value.shareUrl, '_blank', 'noopener,noreferrer');
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(`${baseUrl}/post/${post.id}`);
    dialogStore.createToasterNotification(
      'success',
      `${t('notification')}`,
      `${t('copySuccessMessage')}`
    );
  };

  return {
    shareFacebook,
    shareTwitter,
    shareWhatsapp,
    shareReddit,
    copyUrl
  };
}
