import type { NitroFetchRequest, $Fetch } from 'nitropack';
import type {
  PostResponse,
  CommentResponse,
  Comment,
  Post,
  User,
  postEditResponse
} from '~/interfaces';

export const postRepository = <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
  async getPosts(cursor: string = ''): Promise<PostResponse> {
    const posts = await fetch<PostResponse>(`/api/post?cursor=${encodeURIComponent(cursor)}`);
    return posts;
  },
  async getPost(id: number): Promise<Post> {
    const post = await fetch<Post>(`/api/post/${id}`);
    return post;
  },
  async getPostComments(postId: number, cursor: string = ''): Promise<CommentResponse> {
    const postComments = await fetch<CommentResponse>(
      `/api/post/comment?cursor=${encodeURIComponent(cursor)}&postId=${postId}`
    );
    return postComments;
  },
  async createComment(text: string, postId: number, parentCommentId?: number) {
    const body: any = { text, postId };

    if (parentCommentId !== undefined) {
      body.parentCommentId = parentCommentId;
    }

    const response = await fetch<Comment>('/api/post/comment', {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },
  async reactToPost(postId: number, reactionType: string) {
    const response = await fetch('/api/post/reaction', {
      method: 'POST',
      body: {
        postId: postId,
        reactionType: reactionType
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },
  async getUsersPost(userId: string, cursor: string = '', search?: string): Promise<PostResponse> {
    const response = await fetch<PostResponse>(
      `/api/post/for-user?userId=${userId}&cursor=${cursor}${search ? `&search=${search}` : ''}`
    );
    return response;
  },
  async getTrendingUsers(cursor: string = ''): Promise<User[]> {
    const response = await fetch<User[]>(`/api/post/trending-users?cursor=${cursor}`);
    return response;
  },
  async createNewPost(
    postDescription: string,
    type: string,
    isTicket: boolean,
    shortCompetitionId: string,
    files?: File[] | File,
    externalLink?: string
  ): Promise<Post> {
    const formData = new FormData();
    formData.append('postDescription', postDescription);
    formData.append('type', type);
    formData.append('isTicket', isTicket.toString());
    if (shortCompetitionId) {
      formData.append('shortCompetitionId', shortCompetitionId);
    }

    if (files) {
      if (Array.isArray(files)) {
        files.forEach((file) => formData.append('file', file));
      } else {
        formData.append('file', files);
      }
    }
    if (externalLink) {
      formData.append('externalUrl', externalLink);
    }
    const post = await fetch<Post>('/api/post', {
      method: 'POST',
      body: formData
    });
    return post;
  },
  async reactToComment(postCommentId: number, reactionType: string = 'positiveReaction') {
    await fetch('/api/post/comment-reaction', {
      method: 'POST',
      body: {
        postCommentId,
        reactionType
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async deletePost(postId: number) {
    await fetch(`/api/post/${postId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async deleteComment(commentId: number) {
    await fetch(`/api/post/comment/${commentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async repostPost(text: string, associatedId: number, associatedType: string) {
    await fetch('/api/post/repost', {
      method: 'POST',
      body: {
        text,
        associatedType,
        associatedId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async getPostsFromUsersThatUserFollows(cursor: string = '', type?: string) {
    const response = await fetch<PostResponse>(
      `/api/post/of-following?cursor=${cursor}${type ? `&search=${type}` : ''}`
    );
    return response;
  },
  async editPost(postId: number, postDescription: string, isTicket: boolean) {
    const response = await fetch<postEditResponse>(`/api/post/${postId}`, {
      method: 'PUT',
      body: {
        postDescription,
        isTicket
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },
  async getPinnedPost() {
    const response = await fetch<Post>('/api/post/pinned-post');
    return response;
  }
});
