import type { User } from '~/interfaces';
import { useUserStore } from '~/store/user';
import { userRepository } from '~/repositories/userRepository';

export function useLogin() {
  const cookieToken = useAuthTokenCookie();
  const cookieRefreshToken = useRefreshTokenCookie();
  const userDataCookie = useUserDataCookie();
  const userStore = useUserStore();

  const { $api } = useNuxtApp();
  const userRepo = userRepository($api);

  const login = async (email: string, password: string): Promise<User | null> => {
    const loginData = await userRepo.auth(email.trim().toLowerCase(), password);

    if (loginData?.accessToken && loginData?.refreshToken) {
      cookieToken.value = loginData.accessToken;
      cookieRefreshToken.value = loginData.refreshToken;
    }

    if (loginData?.user) {
      userDataCookie.value = loginData.user;
      userStore.setUser(loginData.user);
      return loginData?.user;
    }
    return null;
  };

  const googleLogin = async () => {
    await userRepo.googleLogin();
  };

  const logout = async () => {
    try {
      await userRepo.logout();
    } catch (err) {
      console.log('Error on user logout service', err);
    }
    logoutLocaly();
  };

  const logoutLocaly = () => {
    cookieToken.value = null;
    userDataCookie.value = null;
    cookieRefreshToken.value = null;
    userStore.setUser(null);
  };

  return {
    login,
    googleLogin,
    logout,
    logoutLocaly
  };
}
