<template>
  <div class="bg-neutral_200 min-h-screen flex items-center justify-center p-12">
    <div v-if="errorContent">
      <img class="max-w-[550px] w-full mx-auto" :src="errorContent.img" alt="Error Image" />
      <div class="flex flex-col gap-10 items-center">
        <p class="text-[32px] text-center font-bold leading-[120%] text-neutral_950">
          {{ errorContent.text }}
        </p>
        <NuxtLink to="/">
          <ButtonComponent size="xl" type="outlined" :cropped="true" :text="`${$t('home')}`" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import img403 from '@/assets/img/403.png';
import img404 from '@/assets/img/404.png';
import img500 from '@/assets/img/500.png';

const props = defineProps<{
  error: NuxtError | null;
}>();

const { t } = useI18n();

const errorClasses: Record<number, { img: string; text: string }> = {
  403: {
    img: img403,
    text: `${t('403Error')}`
  },
  404: {
    img: img404,
    text: `${t('404Error')}`
  },
  500: {
    img: img500,
    text: `${t('500Error')}`
  }
};

const generateStatusCode = (statusCode: number) => {
  if (statusCode != 403 && statusCode != 404 && statusCode != 500) {
    return 500;
  }
  return statusCode;
};

const errorContent =
  props.error?.statusCode && errorClasses[generateStatusCode(props.error.statusCode)];

console.log('Error:', props.error);
</script>

<style></style>
