<template>
  <TransitionRoot as="template" :show="dialogStore.confirmationDialog.isOpen">
    <Dialog
      class="relative z-30"
      @close="
        dialogStore.confirmationDialog.isPersistent ? '' : dialogStore.closeConfirmationDialog()
      "
    >
      <!-- ovo sam dodao da bude kao persistent kada treba akcija da se odradi neka -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden p-8 bg-neutral_500 text-left dialog-shadow transition-all sm:my-8 w-full max-w-[480px]"
            >
              <div>
                <p
                  class="text-base_white text-[12px] font-medium leading-[150%] tracking-[2.4px] uppercase pb-4"
                >
                  {{ dialogStore.confirmationDialog.title }}
                </p>
                <div class="mt-2 flex flex-col gap-4 items-center">
                  <p
                    class="font-medium leading-[150%] tracking-[-0.32px] text-base text-neutral_200"
                  >
                    {{ dialogStore.confirmationDialog.message }}
                  </p>
                  <div class="h-px w-full bg-primary_700" />
                  <ButtonComponent
                    :text="dialogStore.confirmationDialog.confirmButtonText"
                    :cropped="true"
                    @click="confirmDialog"
                  />
                  <ButtonComponent
                    v-if="dialogStore.confirmationDialog.cancelButtonText"
                    :text="dialogStore.confirmationDialog.cancelButtonText"
                    type="text"
                    :cropped="true"
                    @click="denyDialog"
                  />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useDialogStore } from '~/store/dialogStore';

const dialogStore = useDialogStore();

const confirmDialog = async () => {
  dialogStore.closeConfirmationDialog();
  if (dialogStore.confirmationDialog.onConfirm) {
    await dialogStore.confirmationDialog.onConfirm();
  }
};
const denyDialog = async () => {
  dialogStore.closeConfirmationDialog();
  if (dialogStore.confirmationDialog.onCancel) {
    await dialogStore.confirmationDialog.onCancel();
  }
};
</script>
<style scoped>
.dialog-shadow {
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.2),
    0px 6px 6px 0px rgba(0, 0, 0, 0.1);
}
</style>
