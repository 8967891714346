import { useCommonStore } from '~/store/commonStore';

export function useGlobalFunctions() {
  const commonStore = useCommonStore();
  function debounce(func: Function, wait: number) {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: void, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), wait);
    };
  }
  const sendSignalToRealodPosts = debounce(() => {
    commonStore.setShouldReloadPosts(true);
  }, 600);
  const sendRouterBackIfPossible = () => {
    const router = useRouter();
    if (router.options.history.state.back) {
      router.back();
    } else {
      router.push('/');
    }
  };
  return {
    debounce,
    sendSignalToRealodPosts,
    sendRouterBackIfPossible
  };
}
