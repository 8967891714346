import { useCookie } from 'nuxt/app';
import type { User } from '~/interfaces';

export function useAuthTokenCookie() {
  return useCookie('auth_token', {
    httpOnly: false,
    secure: process.env.NODE_ENV === 'production',
    sameSite: process.env.NODE_ENV === 'production' ? 'none' : 'lax',
    maxAge: 31 * 24 * 60 * 60 // 31 days
  });
}

export function useRefreshTokenCookie() {
  return useCookie('refresh_token', {
    httpOnly: false,
    secure: process.env.NODE_ENV === 'production',
    sameSite: process.env.NODE_ENV === 'production' ? 'none' : 'lax',
    maxAge: 31 * 24 * 60 * 60 // 31 days
  });
}

export function useUserDataCookie() {
  return useCookie<User | null>('user_data', {
    httpOnly: false,
    secure: process.env.NODE_ENV === 'production',
    sameSite: process.env.NODE_ENV === 'production' ? 'none' : 'lax',
    maxAge: 31 * 24 * 60 * 60 // 31 days
  });
}
