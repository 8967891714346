<template>
  <div class="w-full py-1 flex items-center justify-between">
    <div class="flex items-center gap-2">
      <UserPicture color="text-neutral_50" gap="gap-2" :user="props.post.user" />
      <!-- <p
        class="text-neutral_50 hidden sm:block text-[14px] font-bold leading-[150%] cursor-pointer"
        @click="router.push({ path: localePath(`/user-posts/${props.post.user.id}`) })"
      >
        {{ post.user.username }}
      </p> -->
      <div class="w-px h-6 bg-primary_850" />
      <ClientOnly v-if="props.post.type == 'repost' && !props.post.prediction">
        <ion-icon class="text-[20px] text-base_white" name="repeat-outline" />
      </ClientOnly>
      <p class="text-neutral_200 text-[12px] font-medium leading-[150%]">
        {{
          props.post.shortCompetitionPost && !props.post.edited_date
            ? formatDateInPredictionFormat(props.post.created_at)
            : formatDateToDayMonthFormat(props.post.created_at)
        }}
      </p>
      <ClientOnly v-if="props.post.edited_date">
        <ion-icon class="text-[20px] text-base_white" name="pencil-outline" />
      </ClientOnly>
      <p
        v-if="props.post.edited_date"
        class="text-neutral_200 text-[12px] font-medium leading-[150%]"
      >
        {{
          props.post.shortCompetitionPost
            ? formatDateInPredictionFormat(props.post.edited_date)
            : formatDateToDayMonthFormat(props.post.edited_date)
        }}
      </p>
    </div>
    <ClientOnly>
      <SettingsPopover
        v-if="popoverActions.length"
        :flex-row-on-small-devices="false"
        :actions="popoverActions"
      />
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import type { Post, settingsItem } from '~/interfaces';
import { postRepository } from '~/repositories/postRepository';
import { useDialogStore } from '~/store/dialogStore';
import { useUserStore } from '~/store/user';

const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true
  },
  place: {
    type: String
  }
});
const emits = defineEmits(['post-deleted', 'open-edit-dialog']);
const { $api, $ability } = useNuxtApp();
const postRepo = postRepository($api);
const dialogStore = useDialogStore();
const userStore = useUserStore();
const userId = userStore.user?.id;
const { t } = useI18n();

const { formatDateToDayMonthFormat, formatDateInPredictionFormat } = useTimeFunctions();

const deletePost = async () => {
  try {
    await postRepo.deletePost(props.post.id);
    emits('post-deleted');
  } catch (err) {
    console.log(err);
  }
};

const askForPermissionOnDelete = () => {
  dialogStore.createConfirmationDialog(
    `${t('confirmation')}`,
    `${t('deletePostQuestion')}`,
    `${t('yes')}`,
    `${t('no')}`,
    deletePost
  );
};

const openEditDialog = () => {
  emits('open-edit-dialog');
};

// const popoverActions: settingsItem[] = [
//   {
//     text: `${t('postDelete')}`,
//     action: askForPermissionOnDelete
//   },
//   {
//     text: t('editPost'),
//     action: openEditDialog
//   }
// ];

const canDeletePost = computed(() => {
  return $ability.value.can('delete', 'Post');
});

const popoverActions = computed<settingsItem[]>(() => {
  // Za ove tipove ne treba popover
  if (props.place === 'onRepostedPost' || props.place === 'repostDialog')
    return [] as settingsItem[];

  // Ako je obican user i njegov je post
  if (userId && userId == props.post.user.id) {
    return [
      { text: t('editPost'), action: openEditDialog },
      { text: t('postDelete'), action: askForPermissionOnDelete }
    ] as settingsItem[];
  } else if (canDeletePost.value) {
    // ako je user ADMIN
    return [{ text: t('postDelete'), action: askForPermissionOnDelete }];
  }
  return [];
});
</script>
