import { defineNuxtPlugin } from '#app';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';
import { useUserStore } from '~/store/user';

export default defineNuxtPlugin((nuxtApp) => {
  const userStore = useUserStore();

  const ability = ref(createMongoAbility([]));

  const updateAbilities = () => {
    const { can, cannot, build } = new AbilityBuilder(createMongoAbility);
    const userPermissions = userStore.getUserAbilities();

    if (userPermissions && userPermissions.length) {
      userPermissions.forEach((permission) => {
        if (permission.inverted) {
          cannot(permission.action, permission.subject, '');
        } else {
          can(permission.action, permission.subject, '');
        }
      });
    }

    ability.value = build();
  };

  updateAbilities();

  watch(
    () => userStore.user,
    () => {
      updateAbilities();
    }
  );

  nuxtApp.vueApp.use(abilitiesPlugin, ability.value);

  nuxtApp.provide('ability', ability);
});
