import { reactive } from 'vue';
import { io, type Socket } from 'socket.io-client';

interface State {
  connected: boolean;
}

export const state = reactive<State>({
  connected: false
});

let socket: Socket | null = null;

if (import.meta.client) {
  socket = io(window.location.host, {
    transports: ['websocket']
  });

  socket.on('connect', () => {
    state.connected = true;
  });

  socket.on('disconnect', () => {
    state.connected = false;
  });
}

export const getSocket = () => socket;
