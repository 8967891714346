import { decodeJwtToken } from '~/utils/jwt';
import { userRepository } from '~/repositories/userRepository';
import { useDialogStore } from '~/store/dialogStore';
import { useRoute } from '#imports';

export default defineNuxtPlugin(() => {
  const $api = $fetch.create({
    onRequest: async ({ request, options }) => {
      const userToken = useAuthTokenCookie();
      if (userToken.value) {
        // Add Authorization header
        const headers = new Headers(options.headers);
        headers.set('Authorization', `Bearer ${userToken.value}`);
        options.headers = headers;

        const requestUrl = typeof request === 'string' ? request : request.url;
        const url = new URL(requestUrl, process.env.BASE_URL || 'http://localhost');
        if (
          !url.pathname?.includes('/api/refresh') &&
          !url.pathname?.includes('/api/auth') &&
          !url.pathname?.includes('/api/logout')
        ) {
          const decodedToken = decodeJwtToken(userToken.value as string);
          const isTokenExpired = Date.now() >= decodedToken.exp * 1000;

          const userRefreshToken = useCookie('refresh_token');
          if (isTokenExpired && userRefreshToken.value) {
            const userRepo = userRepository($api);
            const newAuthToken = await userRepo.refreshToken(userRefreshToken.value);
            userToken.value = newAuthToken;
            refreshCookie('auth_token');
            console.log('token refreshed');

            const headers = new Headers(options.headers);
            headers.set('Authorization', `Bearer ${newAuthToken}`);
            options.headers = headers;
          }
        }
      }
    },
    onResponse() {},
    onResponseError({ response }) {
      const route = useRoute();
      if (response.status === 401) {
        const dialogStore = useDialogStore();
        if (!import.meta.server && route.path !== '/login') {
          const { logoutLocaly } = useLogin();
          logoutLocaly();
          dialogStore.createLoginDialog();
          // navigateTo('/login');
        }
      }
    }
  });
  // Expose to useNuxtApp().$api
  return {
    provide: {
      api: $api
    }
  };
});
