<template>
  <div
    class="flex items-center cursor-pointer"
    :class="props.gap"
    @click="router.push({ path: localePath(`/user-posts/${props.user.id}/all`) })"
  >
    <div
      v-if="!props.user.picture || imageError"
      class="flex items-center justify-center bg-neutral_500 border border-primary_700"
      :class="sizeClasses[props.size]"
    >
      <p class="font-medium text-neutral_50" :class="textSizeClass[props.size]">
        {{ createUserInitials(props.user.username) }}
      </p>
    </div>
    <img
      v-else
      @error="imageError = true"
      :class="sizeClasses[props.size]"
      :src="props.user.picture"
      alt="picutre of user"
    />
    <p
      v-if="props.displayUsername"
      class="font-bold leading-[150%] tracking-[-0.28px] duration-[350ms]"
      :class="[props.color, props.textSize]"
    >
      {{ props.user.username }}
    </p>
  </div>
</template>
<script setup lang="ts">
import type { User, UserInfo } from '~/interfaces';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const props = defineProps({
  size: {
    type: String as PropType<Size>,
    default: 'xs'
  },
  user: {
    type: Object as PropType<User | UserInfo>,
    required: true
  },
  displayUsername: {
    type: Boolean,
    default: true
  },
  color: {
    type: String,
    default: 'text-primary_500'
  },
  textSize: {
    type: String,
    default: 'text-[14px]'
  },
  gap: {
    type: String,
    default: 'gap-4'
  }
});
const { createUserInitials } = useTextFunctions();
const router = useRouter();
const localePath = useLocalePath();
const sizeClasses = {
  xs: 'w-[32px] h-[32px] rounded-[32px]',
  sm: 'w-[40px] h-[40px] rounded-[40px]',
  md: 'w-[48px] h-[48px] rounded-[48px]',
  lg: 'w-[72px] h-[72px] rounded-[72px]',
  xl: 'w-[96px] h-[96px] rounded-[96px]'
};
const textSizeClass = {
  xs: 'text-[14px]',
  sm: 'text-[18px]',
  md: 'text-[22px]',
  lg: 'text-[28px]',
  xl: 'text-[32px]'
};
const imageError = ref(false);
</script>
