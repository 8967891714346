import type { NitroFetchRequest, $Fetch } from 'nitropack';
import type {
  LoginResponse,
  UserProfileResponse,
  UserInfo,
  UserFollowingResponse,
  ChannelResponse,
  User
} from '~/interfaces';

export const userRepository = <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
  async profile(): Promise<UserProfileResponse> {
    try {
      const response = await fetch<UserProfileResponse>('/api/user/profile');
      return response;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw new Error(`Failed to fetch user profile: ${err.message}`);
      } else {
        throw new Error('Failed to fetch user profile: Unknown error');
      }
    }
  },

  async refreshToken(token: string): Promise<string | null> {
    const response = await fetch<{ accessToken: string } | null>('/api/refresh', {
      method: 'POST',
      body: { refresh: token }
    });
    return response?.accessToken || null;
  },

  async auth(email: string, password: string): Promise<LoginResponse> {
    return fetch<LoginResponse>('/api/auth', {
      method: 'POST',
      body: { email, password },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },

  async verify(token: string): Promise<{ success: string; message: string }> {
    return fetch<{ success: string; message: string }>('/api/user-verify', {
      method: 'POST',
      body: { verificationToken: token },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },

  async passwordResetInit(
    email: string,
    language: string
  ): Promise<{ success: string; message: string }> {
    return fetch<{ success: string; message: string }>('/api/user/forgot-password-init', {
      method: 'POST',
      body: { email, language },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },

  async passwordResetChange(
    token: string,
    newPassword: string
  ): Promise<{ success: string; message: string }> {
    return fetch<{ success: string; message: string }>('/api/user/forgot-password-change', {
      method: 'POST',
      body: { passVerificationToken: token, newPassword: newPassword },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },

  async googleLogin(): Promise<void> {
    if (import.meta.client) {
      const origin = window.location.origin;
      const googleLoginUrl = `${origin}/api/google-login`;
      window.location.href = googleLoginUrl;
    }
  },

  async logout(): Promise<boolean> {
    const response = await fetch<boolean>('/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response;
  },

  async register(username: string, email: string, password: string, language: string, recaptchaToken: string, recaptchaV2: boolean) {
    await fetch(`/api/user?recaptchaToken=${recaptchaToken}&recaptchaV2=${recaptchaV2}`, {
      method: 'POST',
      body: { username, email, password, language },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async subscribeUserToChannel(favoriteId: number) {
    await fetch('/api/user/favorite', {
      method: 'POST',
      body: { favoriteId },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async unsubscribeFromChannel(channelId: number) {
    await fetch(`/api/user/favorite/${channelId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async handleUserChannelRelation(isUserSubscribedToChannel: boolean, channelId: number) {
    if (isUserSubscribedToChannel) {
      await this.unsubscribeFromChannel(channelId);
    } else {
      await this.subscribeUserToChannel(channelId);
    }
  },
  async getUserChannelSubscribtions(page: number = 1): Promise<ChannelResponse> {
    const response = await fetch<ChannelResponse>(`/api/user/favorite?page=${page}`);
    return response;
  },
  async getUserProfileInfo(id: string): Promise<UserInfo> {
    const response = await fetch<UserInfo>(`/api/user/info/${id}`);
    return response;
  },
  async getProfilesThatUserFollow(page: number = 1): Promise<UserFollowingResponse> {
    const response = await fetch<UserFollowingResponse>(`/api/user/following?page=${page}`);
    return response;
  },
  async getUserFollowers(page: number = 1): Promise<UserFollowingResponse> {
    const response = await fetch<UserFollowingResponse>(`/api/user/followers?page=${page}`);
    return response;
  },
  async followUser(passiveUserId: string) {
    const response = await fetch('/api/user/follow', {
      method: 'POST',
      body: {
        passiveUserId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },
  async unfollowUser(passiveUserId: string) {
    const response = await fetch(`/api/user/unfollow/${passiveUserId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },
  async handleUserFollowing(isFollowing: boolean, passiveUserId: string) {
    if (isFollowing) {
      await this.unfollowUser(passiveUserId);
    } else {
      await this.followUser(passiveUserId);
    }
  },
  async searchUser(username: string, page: number = 1): Promise<UserFollowingResponse> {
    const response = await fetch<UserFollowingResponse>(
      `/api/user/search?username=${username}&page=${page}`
    );
    return response;
  },
  async getInterestingUsers(page: number = 1): Promise<UserFollowingResponse> {
    const response = await fetch<UserFollowingResponse>(`/api/user/attractive-users?page=${page}`);
    return response;
  },
  async uploadUserPicture(pictureFile: File, userId: string) {
    const formData = new FormData();
    formData.append('picture', pictureFile);
    const response = await fetch(`/api/user/${userId}`, {
      method: 'PATCH',
      body: formData
    });
    return response;
  },
  async getSimilarUsers() {
    const response = await fetch<User[]>('/api/user/mutual-followers');
    return response;
  },
  async updateUser(userId: string, username: string) {
    const response = await fetch(`/api/user/${userId}`, {
      method: 'PATCH',
      body: {
        username
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  }
});
