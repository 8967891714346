<template>
  <NuxtLayout>
    <SocketConnection />
    <NuxtPage />
    <ConfirmationDialog />
    <PostDialog v-if="isDialogStoreReady && dialogStore.postDialog.isOpen" />
    <LoginDialog v-if="isDialogStoreReady && dialogStore.loginDialog.isOpen" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useDialogStore } from '~/store/dialogStore';
import { useCommonStore } from '~/store/commonStore';
const dialogStore = useDialogStore();
const commonStore = useCommonStore();
const isDialogStoreReady = ref(false);

const updateScreenWidth = () => {
  commonStore.setCurrentWidth(window.innerWidth);
};

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `Underdogz - ${titleChunk}` : 'Underdogz';
  }
});

onMounted(() => {
  if (import.meta.client) {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);

    nextTick(() => {
      isDialogStoreReady.value = true;
    });
  }
});
</script>
