<template>
  <Popover ref="popoverRef" class="relative">
    <PopoverButton
      class="inline-flex outline-none items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
    >
      <ClientOnly>
        <ion-icon class="text-base_white cursor-pointer" :class="props.size" :name="icon" />
      </ClientOnly>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <PopoverPanel
        class="absolute z-10 flex align-center content-center sm:content-normal w-screen max-w-max bg-neutral_400 rounded-[8px] shadow-xl"
        :class="[
          position,
          props.flexRowOnSmallDevices
            ? 'flex-row sm:flex-col gap-1 p-2 sm:p-5'
            : 'flex-col gap-2 p-4 sm:p-5'
        ]"
      >
        <p
          v-for="(action, index) in actions"
          :key="index"
          class="cursor-pointer text-[14px] text-base_white font-medium leading-[150%] tracking-[-0.28px]"
          @click="handleActionClick(action.action)"
        >
          <template v-if="action.text">
            {{ action.text }}
          </template>
          <template v-else-if="action.icon">
            <div
              class="flex justify-center content-center items-center w-[40px] h-[40px] bg-neutral_600 rounded-full"
            >
              <ion-icon
                class="text-base_white cursor-pointer text-[22px] text-neutral_200 hover:text-base_white"
                :name="action.icon"
              />
            </div>
          </template>
        </p>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import type { settingsItem } from '~/interfaces';

const props = defineProps({
  size: {
    type: String,
    default: 'text-[20px]'
  },
  icon: {
    type: String,
    default: 'ellipsis-horizontal-sharp'
  },
  actions: {
    type: Array as PropType<settingsItem[]>,
    required: true
  },
  position: {
    type: String,
    default: 'top-0 mt-5 right-0'
  },
  flexRowOnSmallDevices: {
    type: Boolean,
    default: true
  }
});

const popoverRef = ref();

const handleActionClick = (action: any) => {
  action();
  if (popoverRef.value) {
    (popoverRef.value as any).$el.querySelector('button').click();
  }
};
</script>
