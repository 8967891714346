<template>
  <div class="w-full">
    <div class="relative">
      <input
        :value="internalValue"
        class="w-full rounded font-bold leading-[150%] tracking-[-0.36px] outline-none border"
        :class="[
          sizeClasses[size],
          backgroundColor,
          textColor,
          placeholderColor,
          borderColor,
          hoverBorder,
          activeBorder,
          error ? 'border-rose-700' : ''
        ]"
        :disabled="disabled"
        :type="inputType"
        :placeholder="placeholderText"
        :step="props.type == 'number' ? '.01' : 'any'"
        @input="updateValue($event.target as HTMLInputElement)"
        @blur="handleBlur"
      />
      <ClientOnly>
        <ion-icon
          v-if="icon"
          class="absolute text-base_white top-1/2 transform -translate-y-1/2"
          :class="iconSizeClasses[size]"
          :name="icon"
        />
      </ClientOnly>
      <ClientOnly>
        <ion-icon
          v-if="type == 'password'"
          class="absolute top-1/2 text-base_white transform -translate-y-1/2 cursor-pointer"
          :class="helperIconSizeClasses[size]"
          :name="showPassword ? 'eye-off' : 'eye'"
          @click="toggleShowPassword"
        />
      </ClientOnly>
      <ClientOnly>
        <ion-icon
          v-if="props.helperIcon && type != 'password'"
          class="absolute top-1/2 transform text-base_white -translate-y-1/2 cursor-pointer"
          :class="helperIconSizeClasses[size]"
          :name="props.helperIcon"
          @click="emit('helper-clicked')"
        />
      </ClientOnly>
    </div>
    <template v-if="error" />
    <p
      v-for="error of errorMessages"
      v-if="displayErrorText"
      :key="error.$uid"
      class="mt-1 ml-2 text-error_400 text-[14px] font-medium"
    >
      {{ error.$message }}
    </p>
  </div>
</template>
<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core';
type Size = 'large' | 'medium' | 'small';
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  size: {
    type: String as PropType<Size>,
    default: 'large'
  },
  backgroundColor: {
    type: String,
    default: 'bg-primary_900'
  },
  textColor: {
    type: String,
    default: 'text-base_white'
  },
  placeholderColor: {
    type: String,
    default: 'placeholder:text-neutral_200'
  },
  placeholderText: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  borderColor: {
    type: String,
    default: 'border-neutral_500'
  },
  hoverBorder: {
    type: String,
    default: 'hover:border-primary_700'
  },
  activeBorder: {
    type: String,
    default: 'focus:border-primary_500'
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessages: {
    type: Array as PropType<ErrorObject[]>,
    default: () => []
  },
  icon: {
    type: String,
    required: false
  },
  helperIcon: {
    type: String,
    required: false
  },
  displayErrorText: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    required: false
  }
});

const emit = defineEmits(['update:modelValue', 'helper-clicked', 'blur']);

const internalValue = ref(props.modelValue);
const showPassword = ref(false);
const inputType = ref(props.type);

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);

const updateValue = (target: HTMLInputElement | null) => {
  if (target) {
    const newValue: any = props.type === 'number' ? parseFloat(target.value) : target.value;
    internalValue.value = newValue;
    emit('update:modelValue', newValue);
  }
};

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  inputType.value = showPassword.value ? 'type' : 'password';
};
const sizeClasses = {
  large: `h-[64px] py-2 text-[18px] ${props.icon ? 'pl-14 pr-5' : props.type == 'password' || props.helperIcon ? 'pl-5 pr-14' : 'px-5'}`,
  medium: `h-[52px] py-2 text-base ${props.icon ? 'pl-12 pr-4' : props.type == 'password' || props.helperIcon ? 'pl-4 pr-12' : 'px-4'}`,
  small: `h-[40px] py-2 text-base ${props.icon ? 'pl-9 pr-4' : props.type == 'password' || props.helperIcon ? 'pl-4 pr-10' : 'px-4'}`
};
const iconSizeClasses = {
  large: 'left-[24px] text-[24px]',
  medium: 'left-[16px] text-[24px]',
  small: 'left-[16px] text-[16px]'
};
const helperIconSizeClasses = {
  large: 'right-[20px] text-[24px]',
  medium: 'right-[16px] text-[24px]',
  small: 'right-[16px] text-[16px]'
};
const handleBlur = () => {
  emit('blur');
};
</script>
