import mitt from 'mitt';
import type { NewUserPredictionResponse, User } from '~/interfaces';

type ApplicationEvents = {
  'user:loginFromDialog': User;
  'user:addedNewPrediction': NewUserPredictionResponse;
};

export default defineNuxtPlugin(() => {
  const emitter = mitt<ApplicationEvents>();

  return {
    provide: {
      event: emitter.emit, // Will emit an event
      listen: emitter.on // Will register a listener for an event
    }
  };
});
