// plugins/localStorage.client.ts
import { defineNuxtPlugin } from '#app';
import { type LocalStorage } from '~/interfaces/index';

const $locally: LocalStorage = {
  getItem(item: string) {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(item);
    }
    return undefined;
  },

  setItem(item: string, value: string) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(item, value);
    }
  },

  removeItem(item: string) {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(item);
    }
  }
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      locally: $locally
    }
  };
});
