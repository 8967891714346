<template>
  <UDModal @close-modal="emits('close-dialog')" :is-modal-open="props.isOpen">
    <div class="w-full p-4 sm:p-6 bg-primary_900 rounded-[12px]">
      <form @submit.prevent="repostPost">
        <div class="flex flex-col gap-2">
          <div class="flex items-center h-[40px] w-full justify-between">
            <p
              class="text-base_white text-[12px] uppercase font-bold tracking-[2.4px] leading-[150%]"
            >
              {{ $t('repost') }}
            </p>
            <ClientOnly>
              <ion-icon
                class="text-[24px] cursor-pointer text-base_white"
                name="close"
                @click="emits('close-dialog')"
              ></ion-icon>
            </ClientOnly>
          </div>
          <div class="w-full text-left">
            <InputText
              v-model="additionalText"
              :size="commonStore.isMobile ? 'small' : 'medium'"
              :placeholder-text="$t('writeComment')"
              :error="v$.additionalText.$error"
              :error-messages="v$.additionalText.$errors"
            />
          </div>
          <Post :post="props.post" place="repostDialog" />
          <div class="flex items-center mt-3 justify-between">
            <ButtonComponent type="text" :text="$t('giveUp')" @click="emits('close-dialog')" />
            <ButtonComponent :text="$t('repost')" :is-loading="isLoading" :form-element="true" />
          </div>
        </div>
      </form>
    </div>
  </UDModal>
</template>
<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import type { Post } from '~/interfaces';
import { postRepository } from '~/repositories/postRepository';
import { useCommonStore } from '~/store/commonStore';
import { useDialogStore } from '~/store/dialogStore';

const emits = defineEmits(['close-dialog', 'post-reposted']);

const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true
  },
  isOpen: {
    type: Boolean,
    required: true
  }
});
const commonStore = useCommonStore();
const dialogStore = useDialogStore();
const { $api } = useNuxtApp();
const { t } = useI18n();
const postRepo = postRepository($api);
const additionalText = ref('');
const isLoading = ref(false);
const rules = {
  additionalText: {
    required: helpers.withMessage(`${t('commentRequired')}`, required)
  }
};
const v$ = useVuelidate(rules, { additionalText }, { $scope: false });

const repostPost = async () => {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    try {
      isLoading.value = true;
      await postRepo.repostPost(additionalText.value, props.post.id, 'post');
      isLoading.value = false;
      emits('post-reposted');
      emits('close-dialog');
      dialogStore.createToasterNotification('success', t('notification'), t('repostSuccess'));
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
