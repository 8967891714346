<template>
  <div v-click-outside="handleCLickOutside" class="relative">
    <ClientOnly>
      <ion-icon
        class="text-base_white cursor-pointer text-[20px] hidden sm:block"
        name="happy"
        @click="showPicker = !showPicker"
      />
    </ClientOnly>
    <Picker
      v-if="showPicker"
      color="#86C232"
      :show-preview="false"
      class="absolute z-10 top-8 emoji-picker-shadow"
      :skin="1"
      :show-skin-tones="true"
      :data="emojiIndex"
      @select="handleEmojiSelected"
    />
  </div>
</template>
<script lang="ts" setup>
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src';
// treba proveriti da se ovo data ne sjebe mozda kao sa animacijom za import
import data from 'emoji-mart-vue-fast/data/twitter.json';
const emits = defineEmits(['emoji-selected']);
const emojiIndex = new EmojiIndex(data);
const showPicker = ref(false);
const handleEmojiSelected = (emoji: any) => {
  emits('emoji-selected', emoji.native);
};
const handleCLickOutside = () => {
  if (showPicker.value) {
    showPicker.value = false;
  }
};
</script>
<!-- ne moze scoped style, ne radi :( -->
<style>
.emoji-mart {
  background-color: #222629;
  border: 1px solid #253512;
}
.emoji-mart-bar {
  border-bottom: 1px solid #3b541d;
}
.emoji-mart-search input {
  border: 1px solid #3b541d;
  background-color: #222629;
  margin-bottom: 6px;
  color: #fff;
}
.emoji-mart-category-label h3 {
  background-color: #222629;
  color: #e6e8ea;
}
.emoji-mart-emoji::before {
  background-color: #4a5359 !important;
}
.emoji-mart-anchor:hover {
  color: #86c232;
}
.emoji-picker-shadow {
  -webkit-box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.62);
  box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.62);
}
</style>
